import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import {calculateScale, calculateSliderPos, calculateValue} from "../../../utils/helpers/scaleFunctions";
import {Control, useController, UseFormSetValue, UseFormTrigger} from "react-hook-form";
import {clearAnotherSliders} from "../../../utils/helpers/clearAnotherSliders";
import {InputValuesType} from "../../../utils/types";

export type RangeProps = {
    id: string;
    isSliderTouched: boolean;
    handleTouchSlider: () => void;
    backendValue: { min: string; max: string; value: string; };
    setFormValue: UseFormSetValue<InputValuesType | {}>
    control: Control,
    trigger: UseFormTrigger<InputValuesType>
};

const FinalSlider = ({id, isSliderTouched, handleTouchSlider, backendValue, setFormValue, control, trigger}: RangeProps) => {
    const minDefault = 0;
    const maxDefault = 25000;
    const sliderStops = 25000;

    const scaleDefault = calculateScale(maxDefault, sliderStops);

    const {field: { value }} = useController({
        name: id,
        control
    })

    const [inputValue, setInputValue] = useState<string>(value);
    const [sliderValue, setSliderValue] = useState<number>(calculateSliderPos(+value, scaleDefault));

    const setDataToForm = (value: string) => {
        handleTouchSlider();
        setFormValue(id, value);
        clearAnotherSliders(id, setFormValue);
        trigger('hot_mass_flow_of_medium');
    }

    const [scale, setScale] = useState<number>(scaleDefault);

    const [minValue, setMinValue] = useState<number>(minDefault);
    const [maxValue, setMaxValue] = useState<number>(maxDefault);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        // internal @mui function
        if (typeof newValue === 'number') {
            setSliderValue(newValue)
            setInputValue(calculateValue(newValue, scale).toString());
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const inputValue = event.target.value;
        if ((+inputValue >= minValue && +inputValue <= maxValue) || inputValue === '') {
            setInputValue(inputValue);
            setSliderValue(calculateSliderPos(+inputValue, scale));
            setDataToForm(inputValue);
        }
    };

    useEffect(() => {
        if (value) {
            setInputValue(value);
            setSliderValue(calculateSliderPos(+value, scale));
        } else if (isSliderTouched) {
            setInputValue('0');
            setSliderValue(0);
        } else if (backendValue && backendValue.value !== '-') {
            if (backendValue.min === backendValue.max) {
                setScale(scaleDefault)
                setSliderValue(calculateSliderPos(+backendValue.value, scaleDefault));
                setInputValue(backendValue.value);
                setMinValue(minDefault);
                setMaxValue(maxDefault);
            } else {
                const actualScale = calculateScale(Number(backendValue.max) - Number(backendValue.min), sliderStops);
                setScale(actualScale)
                setSliderValue(calculateSliderPos(+backendValue.value, actualScale));
                setInputValue(backendValue.value);
                setMinValue(+backendValue.min);
                setMaxValue(+backendValue.max);
            }
        } else {
            setInputValue('0');
            setSliderValue(0);
            setMinValue(minDefault);
            setMaxValue(maxDefault);
            setScale(scaleDefault)
        }
    }, [backendValue, value, isSliderTouched]);

    return (
        <Box sx={{width: '8.5rem'}}>
            <Input
                value={inputValue}
                size="small"
                onChange={handleInputChange}
                type="text"
                disableUnderline
                inputProps={{
                    step: 0.01,
                    min: minValue,
                    max: maxValue,
                    'aria-labelledby': 'input-slider',
                    inputMode: 'numeric',
                }}
            />
            <CustomSlider
                value={sliderValue}
                min={calculateSliderPos(minValue, scale)}
                step={1}
                max={calculateSliderPos(maxValue, scale)}
                scale={() => calculateValue(sliderValue, scale)}
                onChange={handleSliderChange}
                onChangeCommitted={(e, newV) => {
                    setDataToForm(calculateValue(newV as number, scale).toString())
                }}
                size={'small'}
                valueLabelDisplay="auto"
                aria-labelledby="non-linear-slider"
                data-cy="range-slider"
            />
        </Box>
    );
};

export default FinalSlider;


const Input = styled(MuiInput)`
  width: 100%;
  background-color: transparent;
  color: white;
  border: var(--colorVeryBright) solid 0.1rem;
  padding: 2px 0.5rem;
`;

const CustomSlider = styled(Slider)`
  color: white;
`;
