import React from 'react';
import styles from '../../styles/Home.module.css';
import InputItem from './InputItem/InputItem';
import SelectItem from './SelectItem/SelectItem';
import FinalSlider from './Slider/FinalSlider';
import Unit from "../Unit";
import {
    CalculatedInputType, HotMediums, InputValuesType,
    itemType,
    MeasureType,
    MeasureUnitsType,
    StoredUnitsType
} from "../../utils/types";
import {
    Control,
    FieldErrors,
    UseFormRegister,
    UseFormSetValue, UseFormTrigger
} from "react-hook-form";
import {mediumsLabels, sliderLabels} from "../../utils/constants";

const unselectedFieldsHotAir = ['hot_boiler_gas_input', 'cold_mass_flow_of_medium', 'hot_lambda']

export interface InputContainerProps {
    item: itemType;
    hotMedium: HotMediums;
    isSliderTouched: boolean;
    handleTouchSlider: () => void;
    inputCalculatedInBackend: CalculatedInputType;
    errors: FieldErrors<InputValuesType>;
    units: StoredUnitsType,
    watchSliders: string[],
    measureUnits: MeasureUnitsType,
    handleChangeMeasuring: (type: MeasureType, value: string) => void,
    control: Control,
    trigger: UseFormTrigger<InputValuesType>,
    register: UseFormRegister<InputValuesType>,
    setValue: UseFormSetValue<InputValuesType>
}

const ItemContainer: React.FC<InputContainerProps> = ({
 item,
 hotMedium,
 isSliderTouched,
 handleTouchSlider,
 inputCalculatedInBackend,
 units,
 watchSliders,
 measureUnits,
 handleChangeMeasuring,
 control,
 trigger,
 setValue,
 register, errors
}) => {
    const currentSlider = sliderLabels[watchSliders.findIndex(elem => elem !== '')];

    const isMandatorySlider = item.withSlider
        ? hotMedium === "HOT_AIR"
            ? item.id === 'hot_mass_flow_of_medium'
            : watchSliders.every(elem => elem === '') || item.id === currentSlider
        : false;

    const isNoSelectSlider = hotMedium === "HOT_AIR" && unselectedFieldsHotAir.includes(item.id)

    const renderedUnit = item.measure && measureUnits ?
        <Unit
            trigger={trigger}
            control={control}
            measureUnits={measureUnits}
            measure={item.measure as MeasureType}
            id={item.id} units={units}
            handleChangeMeasuring={handleChangeMeasuring}/>
        : <div className={styles.home_input_unit}/>

    const renderedField = item.withSlider
        ? <FinalSlider
            isSliderTouched={isSliderTouched}
            handleTouchSlider={handleTouchSlider}
            setFormValue={setValue}
            control={control}
            trigger={trigger}
            id={item.id}
            backendValue={inputCalculatedInBackend[item.id]}
        />
        : (item.options.length === 0 && !(Object.keys(mediumsLabels).includes(item.id)))
            ? <InputItem item={item} register={register} trigger={trigger}/>
            : <SelectItem item={item} control={control} trigger={trigger} calculateSliders={handleTouchSlider}/>


    return (
        <div key={item.id} className={`relative mt-4 ${isNoSelectSlider && ' select-none pointer-events-none opacity-20'}`}>
            <div className={styles.home_input_container_content}>
                <label className={styles.home_input_title}>
                    {item.title}
                    {isMandatorySlider && ' *'}
                </label>
                {renderedField}
                {renderedUnit}
            </div>
            {errors[item.id] && (
                <div className='w-full flex justify-end'>
                    <p data-testid='input-invalid' className={styles.home_input_validation}>
                        {errors[item.id]!.message}
                    </p>
                </div>
            )}
        </div>
    );
};

export default ItemContainer;
