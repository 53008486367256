import Select, {ControlProps, CSSObjectWithLabel, GroupBase, OptionProps, SingleValue} from 'react-select';
import {translate} from '../../../utils/translate';
import React, {useMemo} from 'react';
import {InputValuesType, itemType, SelectOptionProps} from "../../../utils/types";
import {useAppContext} from "../../../context/AppContext";
import {Control, Controller, UseFormTrigger} from "react-hook-form";
import {mediumsLabels} from "../../../utils/constants";

type SelectItemProps = {
    item: itemType;
    control: Control;
    trigger: UseFormTrigger<InputValuesType>;
    calculateSliders: () => void;
};

type MediumType = 'cold_medium' | 'hot_medium';

const SelectItem = ({ item, control, trigger, calculateSliders }: SelectItemProps) => {
    const {limitations} = useAppContext();

    const handleGetValue = (value: string) => selectOptions.find(elem => elem.value === value) as SelectOptionProps;

    const selectOptions: SelectOptionProps[] = useMemo(() =>
        item.options.length === 0
            ? Object.keys(limitations[item.id as MediumType]).map(elem => ({
                value: elem,
                // @ts-ignore
                label: mediumsLabels[item.id][elem] || elem.split('_').join(' ')
            }))
            : item.options.map((elem: string) => ({
                value: elem,
                label: elem
            })), [item.id, item.options, limitations])

    return (
        <Controller
            name={item.id}
            control={control}
            defaultValue={selectOptions[0].value}
            render={
                ({field: { value, onChange, ...rest}}) => (
                    <Select
                        {...rest}
                        placeholder={translate('-Choose-')}
                        value={handleGetValue(value)}
                        onChange={(option: SingleValue<SelectOptionProps>) => {
                            onChange(option!.value);
                            calculateSliders();
                            trigger();
                        }}
                        isSearchable={false}
                        options={selectOptions}
                        styles={{
                            input: (
                                base: CSSObjectWithLabel
                            ) => ({
                                ...base,
                                userSelect: 'none',
                                pointerEvents: 'none'
                            }),
                            control: (
                                base: CSSObjectWithLabel,
                                state: ControlProps<SelectOptionProps, false, GroupBase<SelectOptionProps>>,
                            ) => selectStylesControl(base, state), // main style
                            singleValue: (base: CSSObjectWithLabel) =>
                                selectStylesSingleValue(base), // select style
                            option: (base, state) => selectStylesOption(base, state), // options style
                            menu: (base) => ({
                                ...base,
                                background: 'var(--colorDark) !important',
                                zIndex: '999'
                            }),
                            menuList: (base) => ({
                                ...base,
                                "::-webkit-scrollbar": {
                                    width: "4px",
                                    height: "0px",
                                },
                                "::-webkit-scrollbar-track": {
                                    background: "#888"
                                },
                                "::-webkit-scrollbar-thumb": {
                                    background: "#f1f1f1"
                                },
                            })
                        }}
                    />
                )
            }
        />
    );
};

export default SelectItem;

const selectStylesControl = (
    base: CSSObjectWithLabel,
    state: ControlProps<SelectOptionProps, false, GroupBase<SelectOptionProps>>,
) => ({
    // main style
    ...base,
    borderRadius: '0',
    border: state.isFocused
            ? 'white solid 1px'
            : 'var(--colorVeryBright) solid 1px',
    fontSize: '1rem',
    width: '8.5rem',
    padding: '0rem',
    margin: '0rem',
    outline: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover, &:focus, &:active': {
        border: 'white solid 1px',
    },
    svg: {
        color: 'white',
    },
});

const selectStylesSingleValue = (base: CSSObjectWithLabel) => ({
    // select style
    ...base,
    color: 'white',
    padding: '0rem',
    margin: '0rem',
});

const selectStylesOption = (
    base: CSSObjectWithLabel,
    state: OptionProps<SelectOptionProps, false, GroupBase<SelectOptionProps>>,
) => ({
    // options style
    ...base,
    color: 'white',
    background: state.isSelected
        ? 'var(--colorWhite20) !important'
        : state.isFocused
            ? 'transparent'
            : '',
    '&:hover': {
        background: 'rgba( 74, 74, 74, 0.3 )',
    },
});
