import React, {useState, RefObject, useMemo} from "react";
import {translate} from "../../utils/translate";
import drop from "../../assets/drop.svg"
import flame from "../../assets/flame.svg"
import Form from "../Form/Form";
import {coldSideData, hotSideData, hotSideDataBIOMASS} from "../../utils/dataForms";
import ItemContainer, {InputContainerProps} from "../FormInputs/ItemContainer";
import {HotMediums} from "../../utils/types";

interface SearchInterfaceProps {
    reference: RefObject<HTMLDivElement>,
    setInitKeysAndDefaultValues: (e: React.MouseEvent<HTMLButtonElement>) => void,
    hotMedium: HotMediums,
    propsToInputs: Omit<InputContainerProps, "item" | "key" | "hotMedium">
}

export const SearchInterface = ({ reference, setInitKeysAndDefaultValues, hotMedium, propsToInputs }: SearchInterfaceProps) => {

    const [hotTab, setHotTab] = useState(true);

    const handleChangeTab = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setHotTab(!hotTab)
    }

    const checkKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    const renderedHotFields = useMemo(() => hotMedium === 'BIOMASS'
        ? hotSideDataBIOMASS
        : hotSideData
        , [hotMedium])

    const renderData = useMemo(() => hotTab
        ? renderedHotFields
        : coldSideData
    , [hotTab, renderedHotFields])

    return (
        <div ref={reference} className="w-full lg:w-1/2">
            <div className={`transition-all min-h-fit ${hotTab ? 'border-hot' : 'border-cold'}`}>
                <div className="flex">
                    <button
                        data-testid='hot-side-tab'
                        onClick={() => setHotTab(true)}
                        className={`flex w-full justify-center pb-1 pt-2 transition-all duration-500 ${
                            hotTab ? 'bg-warm' : 'hover:bg-white/20'
                        }`}
                    >
                        <div className="mb-1 flex items-center gap-2">
                            <img
                                src={flame}
                                alt='flame'
                                className="h-6 w-6" />
                            <span className="uppercase text-white">{translate('Hot side')}</span>
                        </div>
                    </button>

                    <button
                        data-testid='cold-side-tab'
                        className={`flex w-full justify-center pb-1 pt-2 transition-all ${
                            !hotTab ? 'bg-cold before:bg-left' : 'hover:bg-white/5'
                        }`}
                        onClick={() => setHotTab(false)}
                    >
                        <div className="flex gap-2">
                            <img
                                src={drop}
                                alt='drop'
                                className="h-6 w-6" />
                            <span className="uppercase text-white">{translate('Cold side')}</span>
                        </div>
                    </button>
                </div>

                <form
                    onKeyDown={checkKeyDown}
                    data-testid={hotTab ? 'hot-side-container' : 'cold-side-container'}
                    className='min-h-[750px] p-4 flex flex-col'
                >
                    <Form
                        setInit={setInitKeysAndDefaultValues}
                        title={translate(hotTab ? 'Hot side (fluegas)' : 'Cold side (water)')}
                        changeTab={handleChangeTab}
                        hot={hotTab}
                    >
                        {renderData.map((item) => (
                            <ItemContainer
                                item={item}
                                key={item.id}
                                hotMedium={hotMedium}
                                {...propsToInputs}
                            />
                        ))}
                    </Form>
                </form>
            </div>
        </div>
    );
};
