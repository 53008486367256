import styles from './Header.module.css';
import {toast, ToastContainer} from "react-toastify";
import hec_logo from "../../assets/hec_logo.svg"
import aic_logo from "../../assets/aic_logo.svg"
import login_icon from "../../assets/login_icon.svg"
import logout_icon from "../../assets/logout_icon.svg"
import {useAppContext} from "../../context/AppContext";
import {useModalConfig} from "../../utils/hooks/useModalConfig";
import api from "../../api";
import RequireLoginModal from "../Modals/RequireLoginModal";
import ModalWrapper from "../Modals/ModalWrapper";

interface HeaderProps {
    mainLogoRef: React.RefObject<HTMLDivElement>
    loginButtonRef: React.RefObject<HTMLDivElement>
}

const Header = ({ mainLogoRef, loginButtonRef }: HeaderProps) => {

    const { userInfo } = useAppContext();
    const {ref, isShown, setIsShown, isModalClosing, closeSoftly} = useModalConfig(false);

    const handleSignIn = () => {
        setIsShown(true)
    }

    const handleSignOut = () => {
        api.logOut()
            .then(() => {
                userInfo?.logUserOut()
                setIsShown(false)
            })
            .catch(err => {
                console.log(err)
                toast.error(err.message)
            })
    };

    return (
        <>
            <header className="flex w-full justify-between items-center pt-8 pb-4 md:flex-row md:px-8 gap-10">
                <div className="sm:h-16 flex" ref={mainLogoRef}>
                    <img
                        className="w-full h-full"
                        src={hec_logo}
                        alt="Hec Logo"
                    />
                </div>

                <div className="flex justify-end items-center h-full cursor-pointer md:justify-between"
                     onClick={userInfo?.userToken ? handleSignOut : handleSignIn}
                     ref={loginButtonRef}
                >
                    <button className={styles.login_button + ' est-button'}>
                        <img
                            src={userInfo?.userToken ? logout_icon : login_icon}
                            alt="Auth icon"
                            className={styles.auth_icon}
                        />
                        {userInfo?.userToken ? 'Sign out' : 'Sign in'}
                    </button>
                    <img
                        className="w-[40%] h-full hidden md:block"
                        src={aic_logo}
                        alt="Hec Logo"
                    />
                </div>
            </header>

            <ModalWrapper isClosing={isModalClosing} isOpen={isShown}>
                <RequireLoginModal reference={ref} closeModal={closeSoftly} />
            </ModalWrapper>

            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={true}
            />
        </>
    )
};


export default Header;
