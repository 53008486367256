import React from 'react';
import styles from "../../../styles/Home.module.css";
import {InputValuesType, itemType} from "../../../utils/types";
import {UseFormRegister, UseFormTrigger} from "react-hook-form";

type InputItemProps = {
    item: itemType,
    register: UseFormRegister<InputValuesType>,
    trigger: UseFormTrigger<InputValuesType>
}


const InputItem = ({item, register, trigger}: InputItemProps) => {
    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
        let parts = e.currentTarget.value.split('.');
        let sanitizedParts = [parts[0]];

        if (parts.length > 1) {
            sanitizedParts.push(parts.slice(1).join(''));
        }

        e.currentTarget.value = sanitizedParts.join('.').replace(/[^0-9.]/g, '');
    };

    return (
        <input
            {...register(item.id, { pattern: /^(?!.*\..*\.)\d+\.?\d*$/ })}
            onInput={(e) => {
                handleInput(e);
                trigger([
                    'cold_inlet_temperature_of_medium',
                    'cold_outlet_temperature_of_medium',
                    'hot_outlet_temperature_of_medium',
                    'hot_inlet_temperature_of_medium'
                ]);
            }}
            onWheel={() => (document.activeElement as HTMLInputElement).blur()}
            type='text'
            autoComplete='off'
            className={styles.home_input}/>
    )
}

export default InputItem;
